<template>
  <div class="home-wrap">
    <el-container class="main-container">
      <el-header class="header">
        <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
          <el-radio-button :label="false">展开</el-radio-button>
          <el-radio-button :label="true">收起</el-radio-button>
        </el-radio-group>
        <div class="info">
          当前用户：<span>{{ username }}</span> &nbsp;｜&nbsp; 登陆时间：<span
            >{{ nowTime }}</span
          >
          <span @click="quit" class="quit">退出</span>
        </div>
      </el-header>
      <el-container class="contain">
        <div class="sidebar" width="202px">
          <el-menu
            class="el-menu-vertical-demo"
            :collapse="isCollapse"
            :default-active="$router.path"
            router
            :unique-opened="true"
            @select="handleSelect"
          >
            <el-submenu
              v-for="(item, index) in side_list"
              :key="index"
              :index="item + ''"
            >
              <template slot="title">
                <i :class="item[0][1]"></i>
                <span slot="title">{{ item[0][0] }}</span>
              </template>
              <el-menu-item-group>
                <!-- disabled 禁用按钮 -->
                <el-menu-item
                  v-for="(val, i) in item[1]"
                  :key="i"
                  :index="val.path"
                  default-active="/homepage"
                  :disabled="
                    val.path == '/voice/message' ||
                    val.path == '/inform' ||
                    val.path == '/shop/list' ||
                    val.path == '/shop/limit'
                  "
                  >{{ val.name }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
        <div class="main">
          <!-- <productList /> -->
          <router-view></router-view>
        </div>
      </el-container>
      <el-footer class="footer"
        >深圳聚合科技有限公司@202205 | 汇通易货 |&nbsp;
        <a class="put-on" href="https://beian.miit.gov.cn"
          >   豫ICP备2022014588号-1</a
        >
        <div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41090202000505"
            style="
              display: inline-block;
              text-decoration: none;
              margin-top:8px;
              margin-left:15px;
            "
            ><img src="../assets/备案图标.png" style="float: left;" />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
              "
            >
              豫公网安备 41090202000505号
            </p></a
          >
        </div></el-footer
      >
    </el-container>
  </div>
</template>
<script>
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data() {
    return {
      isCollapse: false,
      disabled_show: false,
      super_admin: [
        [["首页", "el-icon-s-home"], [{ name: "首页", path: "/homepage" }]],
        [
          ["商品管理", "el-icon-s-goods"],
          [
            { name: "商品列表", path: "/product/list" },
            { name: "商品录入", path: "/entering" },
          ],
        ],
        [
          ["合同列表", "el-icon-s-cooperation"],
          [
            { name: "合同列表", path: "/contract/list" },
            { name: "创建合同", path: "/create/contract" },
          ],
        ],
        [
          ["店铺管理", "el-icon-s-shop"],
          [
            { name: "店铺列表", path: "/store/list" },
            { name: "店铺授信", path: "/store/extension" },
            { name: "店铺授信额度记录", path: "/store/extension/record" },
          ],
        ],
        [
          ["订单管理", "el-icon-s-order"],
          [{ name: "订单列表", path: "/order/list" }],
        ],
        [
          ["门店收银", "el-icon-s-finance"],
          [{ name: "收银系统", path: "/cashier/desk" }],
        ],
        [
          ["会员管理", "el-icon-user-solid"],
          [
            { name: "会员列表", path: "/member/list" },
            { name: "创建会员", path: "/member/create/VIP" },
          ],
        ],
        [
          ["后台用户管理", "el-icon-s-custom"],
          [
            { name: "管理员列表", path: "/admin/list" },
            { name: "添加管理员", path: "/create/admin" },
          ],
        ],
        [
          ["小程序", "el-icon-s-tools"],
          [{ name: "小程序端配置", path: "/wx/set" }],
        ],
        // [
        //   ["日志管理", "el-icon-s-platform"],
        //   [{ name: "登陆日志", path: "/logs" }],
        // ],
        [
          ["消息", "el-icon-message-solid"],
          [
            { name: "客户留言", path: "/voice/message" },
            { name: "系统通知", path: "/inform" },
          ],
        ],
        // [
        //   ["店铺管理", "el-icon-s-shop"],
        //   [
        //     { name: "店铺列表", path: "/shop/list" },
        //     { name: "店铺授信额度", path: "/shop/limit" },
        //   ],
        // ],
      ],
      admin: [
        [["首页", "el-icon-s-home"], [{ name: "首页", path: "/homepage" }]],
        [
          ["商品管理", "el-icon-s-goods"],
          [
            { name: "商品列表", path: "/product/list" },
            { name: "商品录入", path: "/entering" },
          ],
        ],
        [
          ["订单管理", "el-icon-s-order"],
          [{ name: "订单列表", path: "/order/list" }],
        ],
        [
          ["门店收银", "el-icon-s-finance"],
          [{ name: "收银系统", path: "/cashier/desk" }],
        ],
        [
          ["会员管理", "el-icon-user-solid"],
          [{ name: "会员列表", path: "/member/list" }],
        ],
      ],
      side_list: "",
      username: "",
      nowTime: "",
    };
  },
  components: {
    // productList,
  },

  created() {
    this.nowTime = sessionStorage.getItem("loginTime");
    const data = JSON.parse(secret.Decrypt(localStorage.getItem("sideMenu")));
    this.side_list = data === 1 ? this.super_admin : this.admin;
    this.username = localStorage.getItem("user_id");
    // const data = JSON.parse(secret.Decrypt(this.$cookies.get("sideMenu")));
    // console.log('home---sideMenu--', secret.Decrypt(this.$cookies.get("sideMenu")));
  },
  methods: {
    handleSelect(index) {
      this.$router.push({
        path: index,
      });
    },
    quit() {
      this.$confirm("是否确定退出系统？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/login");
          localStorage.clear();
          this.reload()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消退出",
          });
        });
    },
  },
};
</script>
<style>
* {
  padding: 0 0;
  margin: 0 0;
}
.home-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.main-container {
  height: 100%;
}
body {
  background-color: #f4f4f4;
}
.header {
  height: 2%;
  background-color: #ffffff;
  border-bottom-color: 1px solid #909399;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  /* background-color: #909399; */
}
.el-menu-item-group__title {
  height: 0;
  margin: 0;
  padding: 0;
}
/* .el-menu-vertical-demo {
  height: 200%;
} */
.el-radio-group {
  margin-bottom: 0 !important;
  position: absolute;
  top: 1%;
  left: 1%;
  width: 10%;
}
.el-radio-button {
  width: 50%;
}
.el-radio-button__inner {
  width: 100%;
}
.contain {
  display: flex;
}
.main {
  width: 93%;
  padding: 2%;
}
.sidebar {
  height: 100%;
}
.el-menu-vertical-demo {
  height: 100%;
}
.el-card__body {
  padding: 0 0;
}
.info {
  font-weight: bold;
  float: right;
  display: flex;
  width: auto;
  height: 100%;
  line-height: 400%;
}
.quit {
  margin-left: 10px;
  cursor: pointer;
}
.quit:hover {
  transform: translateY(-5px);
  text-shadow: 0px 5px 5px #409eff;
}
.info span {
  color: #409eff;
}
.footer {
  margin-top: 20px;
  height: 5% !important;
  color: #fff;
  background-color: #303133;
  font-size: 10px;
  line-height: 3;
  text-align: right;
  padding-right: 10%;
  display: flex;
  padding-left: 20%;
  padding-top: 5px;
}
.put-on {
  color: #ffffff;
  text-decoration: none;
}
@media (max-width: 786px) {
  .info {
    display: none;
  }
}
</style>
